import type {
  CollectionResult,
  EditTitleDistributionBody,
  IncludeArr,
  MessageResult,
  PostTitleDistributionBody,
  Result,
  TitleDistributionEntity,
  TitleDistributionJobEntity,
  TitleDistributionJobListParams,
  TitleDistributionListParams,
} from "../../types";
import axios from "../../core/axios";
import { assertSuccess, paramsParser } from "../../core";
import { Headers } from "../index";

export class TitleDistribution {
  static async create(body: PostTitleDistributionBody, auth: string) {
    const resp = await axios.post<Result<TitleDistributionEntity>>(
      "/distribution/title/create",
      body,
      {
        responseType: "json",
        headers: auth ? Headers.Bearer(auth) : undefined,
      },
    );

    return assertSuccess(resp.data).data;
  }

  static async update(
    distributionId: string,
    body: EditTitleDistributionBody,
    auth: string,
  ) {
    const resp = await axios.post<Result<TitleDistributionEntity>>(
      `/distribution/title/${distributionId}`,
      body,
      {
        responseType: "json",
        headers: auth ? Headers.Bearer(auth) : undefined,
      },
    );

    return assertSuccess(resp.data).data;
  }

  static async delete(distributionId: string, version: number, auth: string) {
    const resp = await axios.delete<MessageResult<"deleted">>(
      `/distribution/title/${distributionId}?version=${version}`,
      {
        responseType: "json",
        headers: auth ? Headers.Bearer(auth) : undefined,
      },
    );

    return assertSuccess(resp.data);
  }

  static async get(
    distributionId: string,
    includes?: IncludeArr<TitleDistributionEntity>,
    auth?: string,
  ) {
    const resp = await axios.get<Result<TitleDistributionEntity>>(
      `/distribution/title/${distributionId}` + paramsParser({ includes }),
      {
        responseType: "json",
        headers: auth ? Headers.Bearer(auth) : undefined,
      },
    );

    return assertSuccess(resp.data).data;
  }

  static async find(params: TitleDistributionListParams, auth?: string) {
    const resp = await axios.get<CollectionResult<TitleDistributionEntity>>(
      `/distribution/title` + paramsParser(params),
      {
        responseType: "json",
        headers: auth ? Headers.Bearer(auth) : undefined,
      },
    );

    return assertSuccess(resp.data);
  }

  static async getJob(
    jobId: string,
    includes?: IncludeArr<TitleDistributionJobEntity>,
    auth?: string,
  ) {
    const resp = await axios.get<Result<TitleDistributionJobEntity>>(
      `/distribution/title/job/${jobId}` + paramsParser({ includes }),
      {
        responseType: "json",
        headers: auth ? Headers.Bearer(auth) : undefined,
      },
    );

    return assertSuccess(resp.data).data;
  }

  static async findJob(params: TitleDistributionJobListParams, auth?: string) {
    const resp = await axios.get<CollectionResult<TitleDistributionJobEntity>>(
      `/distribution/title/job` + paramsParser(params),
      {
        responseType: "json",
        headers: auth ? Headers.Bearer(auth) : undefined,
      },
    );

    return assertSuccess(resp.data);
  }
}
